<template>
  <div
    class="page partners"
    :loaded="Boolean(partners) && Boolean(page)"
  >
    <div class="page-header" v-if="page">
      <div class="wrapper">
        <div class="page-title">Partners</div>
        <h3>{{page.page_title}}</h3>
        <h5>{{page.subtitle}}</h5>
        <p>{{page.description}}</p>
      </div>
    </div>

    <section class="partners-list" v-if="partners">
      <div class="wrapper">
        <div class="container">
          <a
            class="partner"
            v-for="partner in partners"
            :href="partner.link"
            target="_blank"
            rel="noopener"
            :key="partner.id"
          >
            <img
              :src="partner.image"
              :alt="partner.name"
              class="partner-image"
            />
            <Icon type="fas" size="small" icon="external-link-alt" />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";

export default {
  name: "Partners",
  components: {
    Icon,
  },
  data() {
    return {
      partners: null,
      page: null,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
  },
  methods: {
    getItems() {
      if (this.pageRef) this.pageRef();
      if (this.partnersRef) this.partnersRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("partners")
        .onSnapshot((page) => {
          this.page = {
            page_title: "",
            subtitle: "",
            image: "",
            ...page.data(),
          };
        });
      this.partnersRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}partners`)
        .orderBy("order")
        .onSnapshot((partners) => {
          this.partners = partners.docs.map((doc) => {
            const partner = doc.data();
            const link = partner.link;
            return {
              ...partner,
              link: /^https?:\/\//.test(link) ? link : 'http://' + link,
            };
          });
        });
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
    if (this.partnersRef) this.partnersRef();
  },
};
</script>

<style scoped>
.page-header h3,
.page-header h5,
.page-header p {
  max-width: 715px;
}

.page-header .wrapper {
  padding: 48px 192px;
  background: url(../assets/images/wb_bg_partners.jpeg) no-repeat 94% center/auto 100%, url(../assets/images/wb_o_partners.svg) no-repeat 32% -54px/16% auto;
  background-color: #EBF5F7;
  border-radius: 16px;
}

.partners-list {
  margin-bottom: 32px;
}

.partners-list .container {
  display: flex;
  flex-wrap: wrap;
}

.partners-list .partner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(25% - 8px);
  height: 290px;
  border-radius: 16px;
  border: 1px solid rgba(101, 102, 98, 0.08);
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  padding: 64px;
  margin: 0 4px 64px;
  text-align: center;
  transition: all .2s ease-out;
}

#app[no-touch] .partners-list .partner:hover {
  box-shadow: 0 24px 32px rgba(52, 52, 52, 0.12);
  transform: translateY(-8px);
}

.partners-list .partner .icon {
  position: absolute;
  top: 16px;
  right: 16px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  color: #1DACD6;
}

#app[no-touch] .partners-list .partner:hover .icon {
  opacity: 1;
}

.partners-list .partner img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1280px), screen and (max-height: 760px) {
  .page-header .wrapper {
    padding: 48px 96px;
    background-image: none;
    border-radius: 0;
  }
  .partners-list .partner {
    width: calc(33.333333% - 8px);
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 840px) {
  .partners-list .partner {
    height: 290px;
  }
  .partners-list .partner {
    width: calc(50% - 8px);
  }
}

@media screen and (max-width: 650px) {
  .partners-list .partner {
    width: 100%;
    height: 320px;
    margin-bottom: 24px;
  }
  .page-header .wrapper {
    padding: 48px 32px;
  }
}
</style>
